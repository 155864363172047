/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/underscore@1.9.1/underscore.min.js
 * - /npm/underscore.string@3.3.5/dist/underscore.string.min.js
 * - /npm/moment@2.22.2/moment.min.js
 * - /npm/moment@2.22.2/locale/nl.min.js
 * - /npm/moment-business-days@1.0.6/index.min.js
 * - /npm/moment-duration-format@2.2.2/lib/moment-duration-format.min.js
 * - /npm/moment-timezone@0.5.21/builds/moment-timezone-with-data-2012-2022.min.js
 * - /npm/bootstrap-daterangepicker@3.0.3/daterangepicker.min.js
 * - /npm/jquery.ajaxq@0.5.2/build/jquery.ajaxq.min.js
 * - /npm/dropzone@5.5.1/dist/dropzone.min.js
 * - /npm/semantic-ui-calendar@0.0.8/dist/calendar.min.js
 * - /npm/fullcalendar@3.4.0/dist/fullcalendar.min.js
 * - /npm/yamljs@0.1.5/bin/yaml.min.js
 * - /npm/stackframe@1.0.4/stackframe.min.js
 * - /npm/error-stack-parser@2.0.2/error-stack-parser.min.js
 * - /npm/codemirror@5.40.2/lib/codemirror.min.js
 * - /npm/xlsx@0.14.0/dist/xlsx.full.min.js
 * - /npm/file-saver@1.3.8/FileSaver.min.js
 * - /npm/jq-ajax-progress@1.0.0/src/jq-ajax-progress.min.js
 * - /npm/cleave.js@1.4.4/dist/cleave.min.js
 * - /npm/pusher-js@4.3.1/dist/web/pusher.min.js
 * - /npm/jquery-contextmenu@2.7.1/dist/jquery.contextMenu.min.js
 * - /npm/showdown@1.8.6/dist/showdown.min.js
 * - /npm/alertifyjs@1.11.1/build/alertify.min.js
 * - /npm/chart.js@2.7.2/dist/Chart.bundle.min.js
 * - /npm/xregexp@4.2.0/xregexp-all.min.js
 * - /npm/jq-ajax-progress@0.1.9/src/jq-ajax-progress.min.js
 * - /npm/localforage@1.7.3/dist/localforage.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
